<template>
  <div class="form-timeline-task">
    <div class="-mx-3">
      <input
        ref="input"
        v-model="taskName"
        :placeholder="$t('timeline.task')"
        type="text"
        class="base-input"
      />
    </div>
    <div class="mt-2">
      <div class="flex items-center">
        <select-date
          :value="dueDate.end"
          :toggle-icon="false"
          :limit-today="false"
          hide-today
          placeholder="Due date"
          placeholder-classes="text-black"
        >
          <template #default="{ attributes }">
            <v-date-picker
              v-model="dueDate"
              v-bind="attributes"
              class="border-0"
              is-range
            />
          </template>
        </select-date>
        <base-select
          v-model="status"
          class="ml-4"
          placeholder-classes="text-black"
          max-height="300"
          placeholder="Status"
          :options="taskStatusOptions"
        />
      </div>
      <div class="flex justify-between items-center mt-3">
        <div class="flex items-center">
          <!-- Api has not supported add task with assigns yet -->
          <!-- <select-task-assign
            v-model="assigns"
            dropdownPosition="bottom-left"
            class="ml-1"
          >
            <button
              slot="toggle"
              class="rounded-full bg-gray-400 w-8 h-8 has-centered-item hover:bg-gray-500"
            >
              <fa-icon size="sm" icon="user-plus" />
            </button>
          </select-task-assign>
          <base-avatar
            class="ml-3"
            :list="assignedUsers"
            :list-max="2"
            size-small
          /> -->
        </div>
        <base-button :disabled="!taskName" @click="onCreate">
          {{ $t('timeline.create') }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VDatePicker from 'v-calendar/lib/components/date-picker.umd';
import SelectDate from '@/components/select/select-date';

const defaultDueDate = () => {
  const [start, end] = [
    [0, 0, 0, 0],
    [23, 59, 59, 999]
  ].map(val => {
    const date = new Date();
    date.setHours(...val);
    return date;
  });
  return { start, end };
};

export default {
  components: {
    SelectDate,
    VDatePicker
  },
  data() {
    return {
      assigns: [],
      taskName: '',
      dueDate: defaultDueDate(),
      status: null
    };
  },
  computed: {
    ...mapGetters({
      getColumns: 'board-column/getSortedColumns'
    }),
    taskStatusOptions() {
      return this.getColumns.map(this.mapColumnToStatus);
    },
    assignedUsers() {
      return this.assigns.map(e => e.user);
    }
  },
  watch: {
    taskStatusOptions: {
      handler(options = []) {
        this.status = options[0]?.value;
      },
      immediate: true
    }
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    mapColumnToStatus(column) {
      return {
        label: column.name,
        value: column.id
      };
    },
    onCreate() {
      const boardID = parseInt(this.$route.params.id, 10);
      const taskAdd = {
        boardID,
        columnID: this.status,
        name: this.taskName,
        startAt: this.dueDate.start,
        endAt: this.dueDate.end
      };
      this.$emit('create', taskAdd);
      this.resetForm();
    },
    resetForm() {
      Object.assign(this.$data, {
        assigns: [],
        taskName: '',
        dueDate: defaultDueDate(),
        status: this.taskStatusOptions[0]?.value
      });
    }
  }
};
</script>

<style scoped lang="scss">
.form-timeline-task {
  min-width: 320px;
  input {
    @apply outline-none border-none;
  }
}
</style>
