<template>
  <div class="flex-1 px-5 grid grid-rows-gantt-with-header">
    <div class="flex items-start flex-wrap">
      <div class="flex-1 flex flex-wrap items-center justify-between">
        <wrapper-pagination-date
          v-model="paginationDate"
          class="my-5"
          :unit="paginationUnit"
        >
          <template #default="{page, methods}">
            <div class="flex flex-wrap items-center">
              <div class="flex">
                <base-button
                  color="transparent"
                  fa-icon="compress"
                  @click="zoomToFit"
                />
                <base-button color="transparent" @click="methods.today">
                  {{ $t('timeline.today') }}
                </base-button>
                <base-button
                  wide="px-4"
                  fa-icon="chevron-left"
                  color="transparent"
                  @click="methods.back"
                />
                <base-button
                  wide="px-4"
                  fa-icon="chevron-right"
                  color="transparent"
                  @click="methods.next"
                />
              </div>
              <span v-if="!config.fit_tasks" class="text-2xl ml-4 font-medium">
                {{ page.format(paginationFormat) }}
              </span>
            </div>
          </template>
        </wrapper-pagination-date>
        <div class="flex flex-wrap md:items-center">
          <header-timeline-right
            :zoom.sync="zoom"
            :zoom-options="zoomOptions"
            :query.sync="fetchTasksString"
            :tags.sync="fetchTasksTags"
            :status.sync="columnId"
            :results="countTasks"
            :clearable="shouldShowSearchResult"
            @clear="clearFilters"
          />
          <v-popover
            class="sm:ml-5 my-5"
            popover-inner-class="bg-white shadow border rounded"
            :trigger="popoverTrigger"
            @show="focusNewTaskName"
          >
            <base-button
              v-if="!isUserViewer"
              :disabled="createTaskDisabled"
              @click="openCreateTask"
            >
              <base-icon :size="12" icon="plus" />
              <span class="text-sm">
                {{ $t('timeline.create') }}
              </span>
            </base-button>
            <div v-if="createTaskDisabled" slot="popover">
              <p class="text-sm p-3 text-gray-caption">
                {{ $t('timeline.create_disabled') }}
              </p>
            </div>
            <div v-else slot="popover" class="p-3">
              <form-timeline-task
                ref="autoFocus"
                class="relative pt-3"
                @create="onCreateTask"
              />

              <base-button
                ref="closePopover"
                v-close-popover
                wide="py-1 px-2"
                borderless
                color="transparent"
                class="absolute top-0 right-0 z-10 m-1"
              >
                <base-icon size="12" icon="close" />
              </base-button>
            </div>
          </v-popover>
        </div>
      </div>
    </div>

    <div class="relative overflow-hidden">
      <chart-gantt
        ref="ganttChart"
        class="board-timeline__gantt v-gantt-chart"
        :show-today="zoom !== 3"
        :chart-data="chartData"
        :config="config"
        :templates="templates"
        :events="events"
        :locale="$i18n.locale"
        @created="onGanttCreated"
      />
      <div v-if="isLoading" class="background--loading">
        <div class="has-centered-item" style="height: 500px">
          <div class="text-center">
            <base-spinner size="40" />
          </div>
        </div>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import { VClosePopover } from 'v-tooltip';
import ChartGantt from '@/components/chart/chart-gantt';
import WrapperPaginationDate from '@/components/wrapper/wrapper-pagination-date';
import MembersVisible from '@/mixins/MembersVisible';
import FetchTasks from '@/mixins/FetchTasks';
import HeaderTimelineRight from '@/components/header/header-timeline-right.vue';
import FormTimelineTask from '@/components/forms/form-timeline-task';
import GanttUtils from '@/mixins/GanttUtils';

const ZOOM_CONFIG_DEFAULT = 1;

const getInclusiveParams = (isTimeline = true) => ({
  isTimeline
});

export default {
  directives: {
    VClosePopover
  },
  mixins: [MembersVisible, FetchTasks, GanttUtils],
  components: {
    ChartGantt,
    WrapperPaginationDate,
    HeaderTimelineRight,
    FormTimelineTask
  },
  data() {
    const { columnId: colId } = this.$route.query;
    const columnId = !isNaN(colId) ? parseInt(colId, 10) : undefined;
    const { start, end } = this.getStartEndDates(null, 'month');
    const zoomConfigs = this.getZoomConfigs();
    const { scales } = zoomConfigs[ZOOM_CONFIG_DEFAULT];
    const getMarginOffset = position => {
      switch (position) {
        case 'left':
          return '-mr-3';
        case 'right':
          return '-ml-3';
        default:
          return '';
      }
    };
    const getTextTemplate = position => (_0, _1, task) => {
      if (this.getTaskNamePosition(task) === position) {
        return `<div class="${getMarginOffset(position)}">${task.text}</div>`;
      }
      return '';
    };
    return {
      columnId,
      newTaskPosition: {},
      newTaskNode: null,
      config: {
        scales,
        start_date: start,
        end_date: end,
        show_grid: false,
        min_column_width: 40,
        drag_resize: false,
        show_links: false,
        drag_move: false,
        drag_progress: false,
        work_time: true,
        scale_height: 33,
        autosize: false,
        font_width_ratio: 12
      },
      templates: {
        task_class(_, _1, task) {
          const classes = ['timeline__task'];
          if (task.progress === 1) {
            classes.push('task--done');
          }
          return classes.join(' ');
        },
        leftside_text: getTextTemplate('left'),
        rightside_text: getTextTemplate('right'),
        task_text: getTextTemplate('center')
      },
      events: {
        onTaskClick: () => false,
        onTaskDblClick: id => {
          const task = this.getTasks.find(t => t.id === parseInt(id));
          this.$router.push({
            name: `${this.$route.name}-Task`,
            params: {
              task_id: id,
              disable: task?.status === 2 ? true : false
            },
            query: this.$route.query
          });
          return false;
        }
      }
    };
  },
  computed: {
    ...mapGetters('tasks', ['getTasks', 'isEntitiesLoading']),
    ...mapGetters('board-column', ['getSortedColumns']),
    isLoading() {
      return this.isEntitiesLoading;
    },
    statusFilteredTasks() {
      RegExp();
      const filterByColumn = task =>
        !this.columnId || task.boardColumnID === this.columnId;
      return this.getTasks.filter(filterByColumn);
    },
    ganttStaticConfg() {
      return {
        fit_tasks: false
      };
    },
    ganttChart() {
      return this.$refs.ganttChart;
    },
    chartData() {
      const getDate = date =>
        moment(date)
          .locale(this.$i18n.locale)
          .toDate();
      const mapTaskToChartData = task => {
        const { id, name: text, startAt, endAt, status } = task;
        const [start_date, end_date] = [startAt, endAt].map(getDate);
        return {
          id,
          text,
          start_date,
          end_date,
          progress: 1 % ((99 + status) / 100)
        };
      };
      return {
        tasks: this.statusFilteredTasks.map(mapTaskToChartData)
      };
    },
    paginationFormat() {
      const month = 'MMMM';
      const year = 'YYYY';
      const formatAll = [month, year];
      if (this.zoom > 2) formatAll.shift();
      return formatAll.join(' ');
    },
    countTasks() {
      return this.getTasks.length;
    },
    isUserViewer() {
      return this.$store.getters['boards/isUserViewer'];
    },
    createTaskDisabled() {
      return this.getSortedColumns.length === 0;
    },
    popoverTrigger() {
      if (this.createTaskDisabled) return 'hover';
      return 'click';
    }
  },
  watch: {
    columnId(columnId) {
      this.$router.push({ query: { ...this.$route.query, columnId } });
    }
  },
  mounted() {
    this.fetchTasksInclusiveParams = getInclusiveParams(true);
    if (!this.$route.query.isTimeline) {
      this.$router.push({ query: this.fetchTasksInclusiveParams });
    }
  },
  beforeRouteEnter(_, _1, next) {
    next(vm => vm.offSnapshotFirestore({ clear: true }));
  },
  beforeRouteLeave(to, _, next) {
    this.clearTasks();
    if (!to.query.isTimeline) {
      next();
    } else {
      next({
        path: to.path,
        query: getInclusiveParams(false)
      });
    }
  },
  methods: {
    ...mapActions(['resetTaskFilters']),
    ...mapActions('tasks', ['offSnapshotFirestore', 'addTask', 'clearTasks']),

    getZoomConfigs() {
      const today = moment();
      const scaleCss = 'gantt-scale-cell';
      const scaleTodayCss = 'gantt-scale-cell--today';
      const weekendCss = 'gantt-cell--weekend';
      const dayStyle = initCss => date => {
        const css = [initCss, scaleCss];
        const isToday = moment(date).isSame(moment(), 'day');
        const isWeekend = date.getDay() === 0 || date.getDay() === 6;
        if (isToday) css.push(scaleTodayCss);
        else if (isWeekend) css.push(weekendCss);
        return css.join(' ');
      };
      const dayConfig = {
        ...this.getStartEndDates(today, 'week'),
        scales: [
          {
            step: 1,
            unit: 'day',
            format: '%j %l',
            css: dayStyle()
          }
        ]
      };
      const monthConfig = {
        ...this.getStartEndDates(today, 'month'),
        scales: [
          {
            step: 1,
            unit: 'day',
            format: '%d',
            css: dayStyle()
          },
          {
            step: 1,
            unit: 'day',
            format: '%D',
            css: dayStyle()
          }
        ]
      };
      const yearConfig = {
        ...this.getStartEndDates(today, 'year'),
        scales: [{ unit: 'month', format: '%F', step: 1 }]
      };
      return [dayConfig, monthConfig, yearConfig];
    },
    openCreateTask() {
      this.$modal.show('timeline-create-task');
    },
    closePopover() {
      this.$refs.closePopover.$el.click();
    },
    splitToArray(routeValue) {
      return routeValue ? routeValue.split(',').map(e => parseInt(e)) : [];
    },
    async onCreateTask(newTask) {
      const { assigneeIDs, tagIDs } = this.$route.query;
      const fetchTasksTags = this.splitToArray(tagIDs);
      const fetchTasksAssign = this.splitToArray(assigneeIDs);
      const task = {
        ...newTask,
        tagIDs: fetchTasksTags,
        assigneeIDs: fetchTasksAssign
      };
      await this.addTask(task);
      this.closePopover();
      // real-time has been turn off since the sorting order in Timeline is handled by Backend
      // So it is required to refersh all tasks again for that sorting.
      this.refreshTasks();
    },
    focusNewTaskName() {
      setTimeout(() => {
        this.$refs.autoFocus.focus();
      }, 100);
    },
    onGanttCreated() {
      const gantt = this.ganttChart?.gantt;
      const dateFormat = gantt?.date?.date_to_str('%D');
      const dateNumber = date => gantt?.columnIndexByDate(date) + 1;
      const date_scale = date => {
        return `
                <span class="text-black font-semibold">${dateNumber(
                  date
                )}</span>
                <br />
                <span>${dateFormat(date)}</span>
              `;
      };
      this.templates = { ...this.templates, date_scale };
    },
    getTaskNamePosition(task) {
      const gantt = this.ganttChart?.gantt;
      const taskStartPos = gantt?.posFromDate(task.start_date);
      const taskEndPos = gantt?.posFromDate(task.end_date);

      const width = taskEndPos - taskStartPos;
      const textWidth =
        (task.text || '').length * gantt?.config.font_width_ratio;
      if (width < textWidth) {
        const ganttLastDate = gantt?.getState()?.max_date;
        const ganttEndPos = gantt?.posFromDate(ganttLastDate);
        if (ganttEndPos - taskEndPos < textWidth) {
          return 'left';
        } else {
          return 'right';
        }
      } else {
        return 'center';
      }
    }
    // This comment due to there are some problem to display new task box to gantt chart
    // it will be used for clicking in gantt date culumn and toggle new task with the same date
    // setupNewTaskContainer(ev) {
    //   const gantt = this.$refs.gantt.gantt;
    //   const ganttUtilDom = gantt.utils.dom;
    //   const ganttDataArea = ganttUtilDom.closest(ev.target, '.gantt_data_area');
    //   if (this.newTaskNode) ganttDataArea.removeChild(this.newTaskNode);
    //   this.newTaskNode = this.$refs.newTask.$el.cloneNode(true);
    //   this.newTaskNode.classList.remove('invisible');
    //   const position = ganttUtilDom.getRelativeEventPosition(
    //     ev,
    //     gantt.$task_data
    //   );
    //   this.newTaskNode.style.top = `${position.y}px`;
    //   this.newTaskNode.style.left = `${position.x}px`;
    //   ganttDataArea.appendChild(this.newTaskNode);
    //   this.newTaskNode.querySelector('input').focus();
    // },
    // onGanttCellClick(ev) {
    //   const gantt = this.$refs.gantt.gantt;
    //   const ganttUtilDom = gantt.utils.dom;
    //   const taskCell = ganttUtilDom.closest(ev.target, '.gantt_task_cell');
    //   if (!taskCell) return true;
    //   this.setupNewTaskContainer(ev);
    //   const scaleLine = document.querySelector('.gantt_scale_line');
    //   const getLeft = element =>
    //     parseInt(
    //       getComputedStyle(element)
    //         .getPropertyValue('left')
    //         .replace('px', ''),
    //       10
    //     );
    //   const sortByLeftPosition = (a, b) => {
    //     return getLeft(a) - getLeft(b);
    //   };
    //   const columnIndex = Array.from(taskCell.parentNode.children)
    //     .sort(sortByLeftPosition)
    //     .indexOf(taskCell);
    //   // const clickedDay = scaleLine.children.item(columnIndex)?.textContent;
    // },
  }
};
</script>

<style scoped lang="scss">
::v-deep {
  .timeline__scale-cell {
    @apply h-full flex flex-col items-center justify-center leading-snug bg-gray-main;
  }
  .timeline__task {
    @apply rounded bg-brand-blue;
    &.task--done {
      @apply border-green-success bg-green-success;
      .gantt_task_progress {
        @apply bg-green-success;
      }
    }
  }
}
.background--loading {
  @apply top-0 left-0 right-0 bottom-0 absolute;
  z-index: 11000;
}
</style>
