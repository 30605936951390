var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center space-x-5 flex-wrap"},[_c('div',{staticClass:"flex items-center divide-x -mx-5"},[(_vm.clearable)?_c('div',{staticClass:"px-5"},[_c('base-button',{staticClass:"bg-brand-lighter text-sm",attrs:{"color":"brand-outline"},on:{"click":_vm.clearResults}},[_vm._v(" "+_vm._s(_vm.$tc('counts.searchResult', _vm.results))+" "),_c('fa-icon',{staticClass:"ml-2",attrs:{"icon":"times-circle"}})],1)],1):_vm._e(),(_vm.isQuickFilters)?_c('div',{staticClass:"px-5"},[_c('div',{staticClass:"input-group"},[_c('base-input-text',{staticClass:"z-0",attrs:{"value":_vm.query,"icon":"IconSearch","placeholder":_vm.$t('timeline.search'),"type":"text","inner-class":"base-input--gray"},on:{"update":function($event){return _vm.$emit('update:query', $event)}}}),_c('div',{staticClass:"mx-3 my-1 absolute top-0 right-0 cursor-pointer z-10",on:{"click":function($event){return _vm.$emit('update:query', '')}}},[(_vm.query)?_c('base-icon',{attrs:{"width":14,"height":14,"name":"close"}},[_c('icon-close')],1):_vm._e()],1),_c('select-members-tags',{attrs:{"selects":_vm.quickFilters},on:{"update:selects":function($event){_vm.quickFilters=$event},"clear":_vm.clearResults}},[_c('div',{staticClass:"inline-flex items-center h-full px-2 space-x-2 cursor-pointer hover:bg-gray-300 rounded-r"},[_c('span',{staticClass:"text-sm"},[_vm._v(_vm._s(_vm.$t('timeline.header.filter')))]),_c('base-icon',{staticClass:"mt-1",attrs:{"size":"14","icon":"chevron-down","name":"select members tags"}})],1)])],1)]):_c('div',{staticClass:"flex divide-x px-5"},[_c('div',{staticClass:"pr-5 flex items-center"},[_c('board-members',{attrs:{"should-select-commit":false}})],1),_c('div',{staticClass:"flex items-center flex-wrap pl-5 space-x-5"},[_c('div',[_c('select-tags',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('base-button',{staticClass:"text-black",class:{
                  'bg-brand-lighter': active,
                  'text-brand-blue': _vm.localTags.length
                },attrs:{"has-text":!!_vm.localTags.length,"wide":"p-1","name":"select tags","color":active ? 'brand-outline' : 'outline',"icon":"tags","icon-size":28}},[(_vm.localTags.length)?_c('span',{staticClass:"px-10p rounded-full text-sm font-medium badge--brand leading-4"},[_vm._v(" "+_vm._s(_vm.localTags.length)+" ")]):_vm._e()])]}}]),model:{value:(_vm.localTags),callback:function ($$v) {_vm.localTags=$$v},expression:"localTags"}})],1),_c('div',[_c('base-button',{staticClass:"text-black",attrs:{"icon":"search","icon-size":28,"color":"outline","wide":"p-1","name":"toggle quick filter"},on:{"click":function($event){_vm.isQuickFilters = true}}})],1)])])]),_c('div',{staticClass:"flex space-x-5"},[_c('base-select',{attrs:{"value":_vm.zoom,"placeholder":_vm.$t('timeline.status'),"placeholder-classes":"text-black","options":_vm.zoomOptions},on:{"change":function($event){return _vm.$emit('update:zoom', $event)}}}),_c('base-select',{attrs:{"value":_vm.status,"placeholder":_vm.$t('timeline.status'),"placeholder-classes":"text-black","max-height":"200","clearable":"","options":_vm.statusOptions},on:{"change":function($event){return _vm.$emit('update:status', $event)}},scopedSlots:_vm._u([{key:"label",fn:function(ref){
                var props = ref.props;
return [_c('div',{staticClass:"truncate"},[_vm._v(" "+_vm._s(props.label)+" ")])]}},{key:"empty",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('timeline.create_disabled'))+" ")]},proxy:true}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }