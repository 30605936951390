<template>
  <v-popover popover-class="select-members-tags">
    <slot>select</slot>
    <template #popover>
      <div class="py-2">
        <header class="popover__header">
          <h4>
            {{ $t('timeline.header.quick_filters') }}
          </h4>
          <base-button
            :disabled="!hasSelectedSome"
            wide="p-0"
            color="text-brand"
            class="text-sm"
            @click="$emit('clear')"
          >
            {{ $t('timeline.header.clear_all') }}
          </base-button>
        </header>
        <div class="grid grid-cols-2 gap-2 text-sm mt-3">
          <div class="pl-4">
            <p class="font-medium">{{ $t('timeline.header.members') }}</p>
            <v-scroller height="308px" class="pr-3">
              <board-members-item
                v-for="user in users"
                :key="user.id"
                :user="user"
                :selected="selects.assigns[user.id]"
                class="px-0"
                :hoverable="false"
                @select="selectUser(user, $event)"
              />
              <div v-if="canGetNextMembers" keep class="p-2">
                <base-button
                  class="mb-0"
                  full
                  color="link"
                  :loading="loadingMembers"
                  @click="fetchNextMembers"
                >
                  {{ $t('modelTask.card.tags.more') }}
                </base-button>
              </div>
            </v-scroller>
          </div>
          <div>
            <p class="font-medium">{{ $t('timeline.header.tags') }}</p>
            <v-scroller height="308px" class="pr-3">
              <div
                v-for="tag in tags"
                :key="tag.id"
                class="py-1 flex items-center cursor-pointer"
                style="height: 50px;"
                @click="selectTag(tag)"
              >
                <div class="flex-1 flex items-center overflow-hidden">
                  <div
                    class="input--radio flex-perfect-center"
                    :class="{ 'radio--selected': selects.tags[tag.id] }"
                  >
                    <base-icon
                      v-if="selects.tags[tag.id]"
                      class="text-transparent"
                    >
                      <icon-check-circle />
                    </base-icon>
                  </div>
                  <div
                    class="flex items-center rounded-full flex-1 px-4  text-white font-medium overflow-hidden"
                    :style="{ background: tag.color, height: '34px' }"
                  >
                    <p class="truncate" :class="{ invisible: !tag.name }">
                      {{ tag.name || '(empty)' }}
                    </p>
                  </div>
                </div>
              </div>
              <div v-if="canGetNextTags" keep class="p-2">
                <base-button
                  class="mb-0"
                  full
                  color="link"
                  :loading="loadingTags"
                  @click="fetchNextTags"
                >
                  {{ $t('modelTask.card.tags.more') }}
                </base-button>
              </div>
            </v-scroller>
          </div>
        </div>
      </div>
    </template>
  </v-popover>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VScroller from '@/components/scroller';
import boardMembersItem from '../board/board-members-item.vue';
import IconCheckCircle from '../icons/IconCheckCircle.vue';
export default {
  components: { boardMembersItem, VScroller, IconCheckCircle },
  props: {
    selects: {
      type: Object,
      default: () => ({ assigns: {}, tags: {} })
    }
  },
  data() {
    return {
      loadingTags: false,
      loadingMembers: false
    };
  },
  computed: {
    ...mapGetters('tags', {
      tags: 'getTags',
      canGetNextTags: 'getNextPage'
    }),
    ...mapGetters('members', {
      getMembers: 'getMembers',
      canGetNextMembers: 'isNextValid'
    }),
    users() {
      return this.getMembers.filter(m => m.status > 1).map(m => m.user);
    },
    hasSelectedSome() {
      const hasSome = obj => !!Object.keys(obj).length;
      return hasSome(this.selects.assigns) || hasSome(this.selects.tags);
    }
  },
  methods: {
    ...mapActions({
      fetchTags: 'tags/fetchTags',
      fetchMembers: 'members/fetchMembers'
    }),
    async fetchNextUsers() {
      this.loadingMembers = true;
      this.$nextTick(async () => {
        await this.fetchMembers();
        this.loadingMembers = false;
      });
    },
    selectUser(user, isSelected) {
      const selects = { ...this.selects };
      selects.assigns[user.id] = isSelected;
      this.$emit('update:selects', selects);
    },

    fetchNextTags() {
      this.loadingTags = true;
      this.$nextTick(async () => {
        await this.fetchTags();
        this.loadingTags = false;
      });
    },
    selectTag(tag) {
      const selects = { ...this.selects };
      selects.tags[tag.id] = !selects.tags[tag.id];
      this.$emit('update:selects', selects);
    }
  }
};
</script>

<style lang="scss">
.popover {
  &__header {
    @apply flex items-center justify-between px-4;
    h4 {
      @apply font-semibold;
    }
  }

  .input--radio {
    @apply border rounded-full mr-2 cursor-pointer flex-shrink-0;
    width: 15px;
    height: 15px;

    &:not(.radio--selected) {
      @apply border-gray-500;
    }

    &.radio--selected {
      @apply border-brand-blue bg-brand-blue;
    }
  }
}

.select-members-tags.tooltip {
  display: block !important;
  z-index: 10000;

  .tooltip-inner {
    @apply w-screen rounded overflow-hidden;
    max-width: 594px;
    max-height: 399px;
  }

  .tooltip-arrow {
    @apply hidden;
  }

  &.popover {
    .popover-inner {
      @apply rounded;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    }
  }
}
</style>
