<template>
  <div class="flex items-center space-x-5 flex-wrap">
    <div class="flex items-center divide-x -mx-5">
      <div v-if="clearable" class="px-5">
        <!-- shown results -->
        <base-button
          color="brand-outline"
          class="bg-brand-lighter text-sm"
          @click="clearResults"
        >
          {{ $tc('counts.searchResult', results) }}
          <fa-icon class="ml-2" icon="times-circle" />
        </base-button>
      </div>
      <div v-if="isQuickFilters" class="px-5">
        <div class="input-group">
          <base-input-text
            :value="query"
            icon="IconSearch"
            :placeholder="$t('timeline.search')"
            type="text"
            inner-class="base-input--gray"
            class="z-0"
            @update="$emit('update:query', $event)"
          />
          <div
            class="mx-3 my-1 absolute top-0 right-0 cursor-pointer z-10"
            @click="$emit('update:query', '')"
          >
            <base-icon v-if="query" :width="14" :height="14" name="close">
              <icon-close />
            </base-icon>
          </div>
          <select-members-tags
            :selects.sync="quickFilters"
            @clear="clearResults"
          >
            <div
              class="inline-flex items-center h-full px-2 space-x-2 cursor-pointer hover:bg-gray-300 rounded-r"
            >
              <span class="text-sm">{{ $t('timeline.header.filter') }}</span>
              <base-icon
                class="mt-1"
                size="14"
                icon="chevron-down"
                name="select members tags"
              />
            </div>
          </select-members-tags>
        </div>
      </div>
      <div v-else class="flex divide-x px-5">
        <div class="pr-5 flex items-center">
          <!-- board members -->
          <board-members :should-select-commit="false" />
        </div>
        <div class="flex items-center flex-wrap pl-5 space-x-5">
          <div>
            <select-tags v-model="localTags">
              <template #default="{active}">
                <base-button
                  :has-text="!!localTags.length"
                  wide="p-1"
                  name="select tags"
                  :color="active ? 'brand-outline' : 'outline'"
                  icon="tags"
                  :icon-size="28"
                  class="text-black"
                  :class="{
                    'bg-brand-lighter': active,
                    'text-brand-blue': localTags.length
                  }"
                >
                  <span
                    v-if="localTags.length"
                    class="px-10p rounded-full text-sm font-medium badge--brand leading-4"
                  >
                    {{ localTags.length }}
                  </span>
                </base-button>
              </template>
            </select-tags>
          </div>
          <div>
            <base-button
              icon="search"
              :icon-size="28"
              color="outline"
              class="text-black"
              wide="p-1"
              name="toggle quick filter"
              @click="isQuickFilters = true"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="flex space-x-5">
      <base-select
        :value="zoom"
        :placeholder="$t('timeline.status')"
        placeholder-classes="text-black"
        :options="zoomOptions"
        @change="$emit('update:zoom', $event)"
      />
      <base-select
        :value="status"
        :placeholder="$t('timeline.status')"
        placeholder-classes="text-black"
        max-height="200"
        clearable
        :options="statusOptions"
        @change="$emit('update:status', $event)"
      >
        <template #label="{props}">
          <div class="truncate">
            {{ props.label }}
          </div>
        </template>
        <template #empty>
          {{ $t('timeline.create_disabled') }}
        </template>
      </base-select>
    </div>
  </div>
</template>

<script>
import IconClose from '@/components/icons/IconClose';
import BoardMembers from '@/components/board/board-members.vue';
import { mapActions, mapGetters } from 'vuex';
import SelectMembersTags from '../select/select-members-tags.vue';
import SelectTags from '../select/select-tags.vue';

export default {
  components: {
    IconClose,
    BoardMembers,
    SelectMembersTags,
    SelectTags
  },
  props: {
    clearable: Boolean,
    results: {
      type: Number,
      default: 0
    },
    zoom: {
      type: Number,
      default: 0
    },
    zoomOptions: {
      type: Array,
      default: () => []
    },
    tags: {
      type: Array,
      default: () => []
    },
    query: {
      type: String,
      default: ''
    },
    status: {
      type: Number
    }
  },
  data() {
    return {
      isQuickFilters: false
    };
  },
  computed: {
    ...mapGetters({
      getColumns: 'board-column/getSortedColumns',
      members: 'members/getFilterIds'
    }),
    statusOptions() {
      return this.getColumns.map(col => ({ value: col.id, label: col.name }));
    },
    localTags: {
      get() {
        return this.tags;
      },
      set(tags) {
        this.$emit('update:tags', tags);
      }
    },
    quickFilters: {
      get() {
        const mapSelects = arr =>
          arr.reduce((p, c) => ({ ...p, [c]: true }), {});
        return {
          assigns: mapSelects(this.members),
          tags: mapSelects(this.tags)
        };
      },
      set({ assigns, tags }) {
        const toNumArray = obj =>
          Object.keys(obj)
            .filter(e => obj[e])
            .map(e => parseInt(e));
        this.setMembersFilters(toNumArray(assigns));
        this.$emit('update:tags', toNumArray(tags));
      }
    },
    me() {
      return this.$store.getters['members/getUserMember'];
    }
  },
  methods: {
    ...mapActions({
      setMembersFilters: 'members/setFilter'
    }),
    clearResults() {
      this.$emit('clear');
      this.$nextTick(() => {
        // if you are removing timeout there will be bug that
        // after quick filter is closed, all removed filters will be selected back again
        setTimeout(() => {
          this.isQuickFilters = false;
        }, 500);
      });
    }
  }
};
</script>

<style scoped>
@screen lg {
  .input-group {
    @apply w-screen;
    max-width: 375px;
  }
}
</style>
